<template>
  <div>
    <Loading v-if="$global.state.loading"></Loading>
    <v-card v-else outlined>
      <v-card-title> {{ $t('hwamsh-ktab') }} {{ document.title }} </v-card-title>
      <v-divider></v-divider>
      <v-container fluid>
        <v-form @submit.prevent="submit">
          <v-row align="center">
            <v-col cols="8">
              <h3>{{ $t('hwamsh-ktab')}}</h3>
              <v-text-field
                v-model="item.text"
                required
                outlined
                filled
                class="mt-2"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn x-large type="submit" color="primary">
                <v-icon>mdi-send</v-icon>
                <span>{{$t('save')}}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-timeline dense>
          <v-slide-x-reverse-transition group hide-on-leave>
            <v-timeline-item
              v-for="item in items"
              :key="item.id"
              color="primary"
              small
              fill-dot
            >
              <v-card :icon="item.icon" outlined>
                <v-container>
                  {{ item.text }}
                </v-container>
                <v-container fluid>
                  <div class="d-flex align-center">
                    <p>
                      <v-icon>mdi-account</v-icon>
                      {{ item.user.fullName }}
                    </p>
                    <p class="mx-5">
                      <v-icon>mdi-calendar</v-icon>
                      <i class="mx-2" dir="ltr">{{
                        $service.formatDate(item.created)
                      }}</i>
                    </p>
                  </div>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
        </v-timeline>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      document: {},
      tab: 0,
      searchUser: "",
      item: {
        text: "",
        documentId: "",
      },
      items: [],
    };
  },
  methods: {
    getDocument() {
      this.$global.state.loading = true;
      this.$http
        .get("/document", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.document = response.data.result[0];
          this.$global.state.loading = false;
        });
    },
    async submit() {
      try {
        this.$global.state.loading = true;
        this.item.documentId = this.$route.params.id;
        await this.$http.post("/documentcomment", this.item);

        this.item.text = "";
        this.getComments();
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    async getComments() {
      this.$global.state.loading = true;
      let res = await this.$http.get("/documentcomment", {
        params: {
          documentId: this.$route.params.id,
        },
      });
      this.items = res.data.result;
      this.$global.state.loading = false;
    },
  },
  mounted() {
    this.getDocument();
    this.getComments();
  },
};
</script>